"use strict";
const tools = require('../tools');
import Collapsible from '../Collapsible';

class NavCollapsible extends Collapsible {
    constructor(wrapperElt=HTMLElement,
                btnsElt=[HTMLElement],
                collapsibleElt=HTMLElement,
                areaElt =HTMLElement,
                parentCol=null
    ) {
        super(wrapperElt, btnsElt, collapsibleElt, areaElt);
        this.parentCol = parentCol;
    }

    getBtnClickAction() {
        return (e) => {
            let proceed = false;
            if(!this.isOpen || e.target.tagName !== 'A') {
                e.stopPropagation();
                e.preventDefault();
                proceed = true;
            }

            if(proceed) {
                this.isOpen = !this.isOpen;
                this.resize();
            }
        }
    }

    setHeights(px) {
        // if(tools.isMobileNav()) {
        //     if(this.parentCol) {
        //         this.parentCol.addHeight(px > 0 ? px : - this.area.offsetHeight);
        //     }
        //     this.collapsible.style.height = px + 'px';
        //     this.collapsible.style.maxHeight = px + 'px';
        // } else {
        //     this.collapsible.style.height = 'auto';
        //     this.collapsible.style.maxHeight = 'none';
        // }
    }


    addHeight(px) {
        // this.setHeights(this.area.offsetHeight + px);
    }

    enable() {
        tools.each(this.btns, btn => {

            btn.unbind('click');
            btn.addEventListener('click', this.btnClickAction);

        })
        this.resize();
    }

    disable() {
        tools.each(this.btns, btn => {
            btn.removeEventListener('click', this.btnClickAction);
        });
    }
}

export default NavCollapsible;
