import { Fancybox } from "@fancyapps/ui";

import tools from "./tools";
import Navigation from "./navigation/Navigation";
import LazyImg from "./lazy/LazyImg";

const app = {
    init() {
        document.documentElement.classList.remove('no-js');
        app.lazyness();
        app.fancyGallery();
        app.mobileNavigation();
        app.dimmBackground();
    },

    mobileNavigation() {
        let navEl = document.getElementById('global-nav');
        if(navEl) {
            let navigation = new Navigation(navEl);
            navigation.init();
        }
    },

   dimmBackground() {
       let contentEl = document.getElementById('content');

       let navLinks = document.querySelectorAll("#l2Nav a");

       if(navLinks) {
           tools.each(navLinks, navLink => {
               navLink.addEventListener('mouseover', function( event ) {
                   contentEl.classList.add('dimmed');
               });
               navLink.addEventListener('mouseleave', function( event ) {
                   contentEl.classList.remove('dimmed');
               });
           });
       }

    },

    lazyness() {
        new LazyImg();
    },
    fancyGallery(){

        Fancybox.bind(".gallery a", {
            groupAll : true, // Group all items
            Toolbar: false,
            closeButton: "top",
            friction: 0,
            // animated: false,
            // showClass: false,
            // hideClass: false,
            Image: {
                zoom: false,
            },
            on : {
                ready : (fancybox) => {
                    // console.log(`fancybox #${fancybox.id} is ready!`);
                }
            }
        });

        // const queryString = window.location.search;
        // const urlParams = new URLSearchParams(queryString);
        // const img = urlParams.get('img');
        // if(img)
        //     document.getElementById(img).click()

    }
};

document.addEventListener('DOMContentLoaded', () => {
    app.init();
});

window.sukoaUtils = {
    dcmadr: function (nnnn) {
        var a = "";
        for (let i = 0; i < nnnn.length; i++) {
            if (i % 3 === 0) {
                a += String.fromCharCode(nnnn.substr(i, 3));
            }
        }
        location.href = (a);
    }
};
