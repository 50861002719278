"use strict";
const tools = require('./tools');

class Collapsible {

    constructor(wrapperElt=HTMLElement,
                btnsElt=HTMLCollection,
                collapsibleElt=HTMLElement,
                areaElt =HTMLElement
    ) {
        this.wrapper = wrapperElt;
        this.btns = btnsElt;
        this.collapsible = collapsibleElt;
        this.area = areaElt;

        this.collapsedClass = "fold";
        this.unfoldClass = "unfold";

        this.btnClickAction = this.getBtnClickAction();
        if(this.wrapper && this.btns && this.collapsible && this.area) {
            this.isOpen = this.wrapper.classList.contains(this.unfoldClass);

            this.init();
        }
    }

    init() {
        tools.each(this.btns, btn => btn.addEventListener('click', this.btnClickAction));
        tools.doOnWindowResize({arg: this, fn: (arg) => {arg.resize()}});
        if(this.isOpen) {
            this.resize();
        }
    }

    getBtnClickAction() {
        return (e) => {
            e.stopPropagation();
            e.preventDefault();
            this.isOpen = !this.isOpen;
            this.resize();
        }
    }

    resize() {
        if(this.isOpen) {
            this.open();
        } else {
            this.close();
        }
    }

    setHeights(px) {
        this.collapsible.style.height = px + 'px';
        this.collapsible.style.maxHeight = px + 'px';
    }

    open() {
        this.isOpen = true;
        this.wrapper.classList.remove(this.collapsedClass);
        this.wrapper.classList.add(this.unfoldClass);
        this.setHeights(this.area.offsetHeight);
    }

    close() {
        this.isOpen = false;
        this.wrapper.classList.remove(this.unfoldClass);
        this.wrapper.classList.add(this.collapsedClass);
        this.setHeights(0);
    }

}


export default Collapsible;
