'use strict';
const tools = require('../tools');
import NavCollapsible from './NavCollapsible';

class Navigation {
    constructor(navEl = HTMLElement) {
        this.el = navEl;
        this.mobileInitialized = false;
        this.mobileClickHandlers = [];
    }

    init() {
        if (this.el) {
            this.initMobile();
            this.initBurger();
            tools.doOnWindowResize({arg: this, fn: arg => arg.initMobile()})
        }
    }

    initMobile() {
        if (tools.isMobileNav()) {
            this.activateMobile();
        } else {
            this.deactivateMobile();
        }
    }

    deactivateMobile() {
        if(this.mobileInitialized) {
            tools.each(this.mobileClickHandlers, mCol => mCol.disable())

        }
    }

    activateMobile() {
        if(this.mobileInitialized) {

            tools.each(this.mobileClickHandlers, mCol => mCol.enable( ))

        } else {

            let lvl2wraps = this.el.querySelectorAll('.nav-lvl-1 > li');

            let initLevel2 = lvl2wrap => {
                let btns = lvl2wrap.querySelectorAll('.btn-1');

                let collapsibleWrapper = lvl2wrap.querySelector('.nav-lvl-2');

                if (collapsibleWrapper) {
                    let collapsibleArea = collapsibleWrapper;
                    if (btns && collapsibleArea) {
                        let collapsible = new NavCollapsible(lvl2wrap, btns, collapsibleWrapper, collapsibleArea);
                        this.mobileClickHandlers.push(collapsible);
                    }
                }
            }

            tools.each(lvl2wraps, initLevel2);

            this.mobileInitialized = true;

        }
    }

    initBurger() {
        let burger = document.getElementById('burger');
        // let openSubnavs = this.el.querySelectorAll('.nav-lvl-1 > li.open');
        // if(openSubnavs){
        //     openSubnavs.forEach(openSubnav => {
        //         openSubnav.classList.add('unfold');
        //     })
        // }
        if(burger) {
            burger.addEventListener('click', e => {

                if(this.el.classList.contains('open')) {
                    this.el.classList.remove('open');
                } else {
                    this.el.classList.add('open');
                }
                if(burger.classList.contains('open')) {
                    burger.classList.remove('open');
                } else {
                    burger.classList.add('open');
                }
                if(document.body.classList.contains('nav-open')) {
                    document.body.classList.remove('nav-open');
                } else {
                    document.body.classList.add('nav-open');
                }
            })
        }
    }
}


export default Navigation;
